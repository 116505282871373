/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform text-sm -translate-y-3;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', cursive;
}

.swipeable_modal .ion-page, .modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md, ::part(background) {
  background: transparent;
  height: 100%;
}

.swipeable_modal .ion-page, .modal-wrapper.sc-ion-modal-ios, .modal-shadow.sc-ion-modal-ios, ::part(background) {
  background: transparent;
  height: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  background: #C0E21B;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background: #C0E21B;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

input[type="radio"]:focus {
  outline: none;
}

.rounded-image{
  border-radius: 100% !important;
}